// AiDrawing.js
import React, { useState } from 'react';

const AiDrawing = () => {

    const styles = ["fantasy", "photo", "2d", "colorful"];
    const aspectRatios = ["landscape", "portrait", "square"];
    const randomNumber = Math.floor(Math.random() * 4) + 1 - 1;
    // random seed from 0 to 3 integer



    const [prompt, setPrompt] = useState();
    const [style, setStyle] = useState(styles[randomNumber]);
    const [aspectRatio, setAspectRatio] = useState("square");
    const [scale, setScale] = useState(7);
    const [image, setImage] = useState();
    const [isGenerating, setIsGenerating] = useState(false);


    const generateImage = async () => {
        // random seed
        const seed = Math.floor(Math.random() * 10000000000);
        const queryParams = new URLSearchParams({
            prompt: prompt,
            style: style,
            aspect_ratio: aspectRatio,
            seed: seed,
            scale: scale,
        });

        console.log(queryParams.toString());
        setIsGenerating(true);
        try {
            const response = await fetch(
                `https://api.mvpro.lt/generate/?${queryParams.toString()}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Auth-token": "lUr0Jm9u!Gij7D5MvBZgAhupTDYJvJCw4n!xpONi/vvGt1sRAcdbt8CdKbG-=yEV"
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const jsonResponse = await response.json();
            const imageName = jsonResponse.image_name;

            // Fetch the image file using the image_name from the response
            const imageResponse = await fetch(`https://api.mvpro.lt/images/${imageName}`);

            if (!imageResponse.ok) {
                throw new Error(`HTTP error! status: ${imageResponse.status}`);
            }

            const base64Image = btoa(
                new Uint8Array(await imageResponse.arrayBuffer()).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                )
            );
            setImage(`data:image/png;base64,${base64Image}`);
        } catch (error) {
            console.error("Error generating image:", error);
        } finally {
            setIsGenerating(false);
        }
    };



    return (
        <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-2/3 h-full relative">
                {isGenerating ? (
                    <div className="mt-9 absolute inset-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-50">
                        <div className="loader"></div>
                        <span className="text-gray-400 ml-2">Generating image...</span>
                    </div>
                ) : (
                    image && (
                        <img
                            src={image}
                            alt="Generated AI Drawing"
                            className="object-contain w-full h-full p-4"
                        />
                    )
                )}
            </div>

            <div className="w-full md:w-1/3 flex flex-col space-y-4 p-4">
                <p className="text-sm m-0 p-0 text-gray-400">Add prompt for generation</p>
                <textarea
                    className="resize-none h-20 p-1 border border-gray-300 rounded"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Enter text here"
                />
                <p className="text-sm m-0 p-0 text-gray-400">Select style</p>
                <select
                    className="p-1 border border-gray-300 rounded"
                    value={style}
                    onChange={(e) => setStyle(e.target.value)}
                >
                    {styles.map((s) => (
                        <option key={s} value={s}>{s}</option>
                    ))}
                </select>
                <p className="text-sm m-0 p-0 text-gray-400">Select aspect ratio</p>
                <select
                    className="p-1 border border-gray-300 rounded"
                    value={aspectRatio}
                    onChange={(e) => setAspectRatio(e.target.value)}
                >
                    {aspectRatios.map((ratio) => (
                        <option key={ratio} value={ratio}>{ratio}</option>
                    ))}
                </select>
                <p className="text-sm m-0 p-0 text-gray-400">Details</p>
                <input
                    className="w-full"
                    type="range"
                    min={2}
                    max={20}
                    value={scale}
                    onChange={(e) => setScale(e.target.value)}
                    style={{
                        '--track-color': '#4B5563',
                        '--thumb-color': '#F59E0B',
                    }}
                />


                <button
                    className={`bg-purple-600 text-white py-2 px-4 rounded ${!prompt || isGenerating ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
                        }`}
                    onClick={generateImage}
                    disabled={!prompt || isGenerating}
                >
                    Generate
                </button>
            </div>
        </div>
    );

};

export default AiDrawing;
